<!--
 * @Author: lanxx
 * @Date: 2021-11-11 09:31:38
 * @LastEditTime: 2022-05-28 09:59:27
 * @LastEditors: lanxx
 * @Description: Do not edit
 * @FilePath: \prosperity-garden-ui\src\views\county.vue
-->
<template>
  <div
    class="container"
    v-loading="loading"
    element-loading-text="加载中"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="main">
      <top-header></top-header>
      <div class="content">
        <div class="left">
          <div class="top-total">
            <div class="item">
              <div class="body">
                <span class="num">{{ titleData.distributeAmountYears }}</span>
                <span class="unit">万元</span>
                <div class="name">当年度已分配总额</div>
              </div>
            </div>
            <div class="item addbody">
              <div class="body">
                <span class="num">{{ titleData.totalHouse }}</span>
                <span class="unit">户</span>
                <div class="name">当年度分配户数</div>
              </div>
            </div>
            <div class="item addbody">
              <div class="body">
                <span class="num">{{ titleData.averageIncome  }}</span>
                <span class="unit">元</span>
                <div class="name">户均分配收入</div>
              </div>
            </div>
          </div>
          <title2 name="全市各镇、街道概况" style="margin: 30px 0 24px;"></title2>
          <div class="bar" ref="bar"></div>
          <title2 name="各菜园已分配收入构成" style="margin: 30px 0 24px;"></title2>
          <div class="shellBox">
          <div class="shell">
            <vue-seamless-scroll :data="townList" :class-option="defaultOption2">
              <div class="vegetable">
                <div class="box" v-for="item in townList" :key="item.orgId">
                  <div class="title">{{item.gardenName}}</div>
                  <div class="heng" />
                  <div class="lists">
                    <div>
                      <span class="text">劳动报酬所得</span
                      ><span class="money">{{item.LABOR}} 元</span>
                    </div>
                    <div>
                      <span class="text">土地流转所得</span
                      ><span class="money">{{item.LAND}} 元</span>
                    </div>
                    <div>
                      <span class="text">蔬菜销售所得</span
                      ><span class="money">{{item.VEGESTABLE}} 元</span>
                    </div>
                    <div>
                      <span class="text">获赠物资所得</span
                      ><span class="money">{{item.GET_GIFT}} 元</span>
                    </div>
                    <div>
                      <span class="text">第三次分配所得</span
                      ><span class="money">{{item.THIRD_ALLOCATION}} 元</span>
                    </div>
                  </div>
                </div>
              </div>
            </vue-seamless-scroll>
          </div>
          </div>
        </div>

        <div class="right">
          <title2 name="各镇、街道分配情况"></title2>
          <ul class="garden-list">
            <li>
              <span>镇、街道</span>
              <span>已分配收入(万元)</span>
              <span>户均分配收入(元)</span>
            </li>
          </ul>
          <ul class="garden-list garden-list-body income">
            <vue-seamless-scroll :data="gardenList" :class-option="defaultOption">
              <li
                v-for="item in gardenList"
                :key="item.orgId"
                @click="goGarden(item.orgId)"
              >
                <span>{{ item.town }}</span>
                <span>{{ item.distribute }}</span>
                <span>{{ item.avgIncome }}</span>
              </li>
            </vue-seamless-scroll>
          </ul>
          <title2 name="已分配收入构成" style="margin: 30px 0 24px;"></title2>
          <div class="pie" ref="pie"></div>
          <div class="pieList" v-for="(item, index) in pieData" :key="index">
            <div class="text">
              <div :class="'dian' + index"  />
              {{item.name}}
            </div>
            <div class="money">{{item.value}} 元</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { $getJson } from "@/utils/fetch";
// import { splitToChunks } from "@/utils/index";
// const cellSize = [0, 0];
export default {
  name: "county",
  data() {
    return {
      loading: true,
      barData: {},
      pieData: [],
      townList: [],
      gardenList: [],
      titleData: {
        totalHouse: 0,
        distributeAmountYears: 0,
        averageIncome : 0
      },
    };
  },
  computed: {
    optionBar() {
      return {
        tooltip: {
          trigger: "axis",
        },
        grid: {
          // 让图表占满容器
          top: "30px",
          left: "30px",
          right: "0px",
          bottom: "30px",
        },
        calculable: true,
        xAxis: [
          {
            type: "category",
            // prettier-ignore

            data: this.barData.town,
            axisLine: {
              show: false,
              onZero: false,
              lineStyle: {
                color: "#ffffff",
              },
            },
            axisTick: {
              show: false, // x轴上的点
            },
            splitLine: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              show: false,
              onZero: false,
              lineStyle: {
                color: "#ffffff",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(0, 149, 255, .5)",
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            name: "销售总额(万元)",
            type: "bar",
            data: this.barData.salesIncome,
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#2B68FF", // 0% 处的颜色
                },
                {
                  offset: 0.5,
                  color: "#1DAEFF", // 100% 处的颜色
                },
                {
                  offset: 1,
                  color: "rgba(77,166,231,0.67)", // 100% 处的颜色
                },
              ],
              global: false, // 缺省为 false
            },
          },
          {
            name: "已分配收入(万元)",
            type: "bar",
            data: this.barData.distribute,
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#F78F1A", // 0% 处的颜色
                },
                {
                  offset: 0.5,
                  color: "#F9B91F", // 100% 处的颜色
                },
                {
                  offset: 1,
                  color: "rgba(249,185,31,0.64)", // 100% 处的颜色
                },
              ],
              global: false, // 缺省为 false
            },
          },
        ],
      };
    },
    optionPie() {
      return {
        tooltip: {
          trigger: "item",
        },
        grid: {
          // 让图表占满容器
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
        },

        series: [
          {
            name: "已分配收入构成",
            type: "pie",
            radius: ["55%", "80%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              formatter: function (params) {
                return params.percent + "%";
              },
              // offset: [-cellSize[0] / 2 + 10, -cellSize[1] / 2 + 10],
              fontSize: 12,
              textStyle: {
                color: "#ffffff",
              },
            },
            labelLine: {
              show: true,
            },

            data: this.pieData,
          },
        ],
      };
    },
    defaultOption() {
      return {
        step: 0.4, // 数值越大速度滚动越快
        limitMoveNum: 3, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: false, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
    defaultOption2() {
      return {
        step: 0.4, // 数值越大速度滚动越快
        limitMoveNum: 4, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: false, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        openTouch: false,
      };
    },
  },
  mounted() {
    // myChart.showLoading({
    //   text: "加载中...",
    //   textColor: "#fff",
    //   maskColor: "transparent",
    // });
    // $getJson("330481").then((res) => {
    //   console.log(res);
    //   this.refresh(res.data.features)
    // });
    this.refresh();
  },
  methods: {
    refresh() {
      this.barData = {};
      this.pieData = []
      let distribute = [];
      let salesIncome = [];
      let town = [];
      // 图形数据处理
      this.$api.bigdata.coRich("330481").then((res) => {
        res.data.townSalesList.map((item) => {
          distribute.push(item.distribute);
          salesIncome.push(item.salesIncome);
          town.push(item.town);
        });
        this.barData.town = Object.assign([], [].slice.call(town));
        this.barData.distribute = Object.assign([], [].slice.call(distribute));
        this.barData.salesIncome = Object.assign([], [].slice.call(salesIncome));
        this.pieData = Object.assign([], [].slice.call(res.data.pieChartData));
        this.townList = res.data.villageIncomeCompositionList
        this.gardenList = res.data.townSalesList
        // 标题数据
        this.titleData = {
          totalHouse: res.data.totalHouse,
          distributeAmountYears: res.data.distributeAmountYears,
          averageIncome: res.data.averageIncome 
        }
        // 柱状图
        const bar = this.$echarts.init(this.$refs.bar);
        bar.setOption(this.optionBar);
        const pie = this.$echarts.init(this.$refs.pie);
        pie.setOption(this.optionPie);
        this.loading = false
      });
    },
  },
};
</script>

<style scoped lang="scss">
.bar {
  width: 100%;
  height: 350px;
}
.pie {
  width: 100%;
  height: 250px;
}
.pieList {
  display: flex;
  justify-content: space-between;
  padding: 4px 24px;
  .text {
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    display: flex;
    align-items: center;
    div {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      opacity: 1;
      margin-right: 5px;
    }
    .dian0 {
      background: #5470c6;
    }
    .dian1 {
      background: #91cc75;
    }
    .dian2 {
      background: #fac858;
    }
    .dian3 {
      background: #ee6666;
    }
    .dian4 {
      background: #73c0de;
    }
  }
  .money {
    font-size: 14px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #ffffff;
  }
}
.shellBox {
  background: url("../assets/images/各菜园bg@2x.png") no-repeat;
  background-size: 100%;
  display: flex;
  justify-content: center;
  border-radius: 8px 8px 8px 8px;
}
.shell {
  width: 1258px;
  height: 298px;
  overflow: hidden;
}
.vegetable {
  width: 100%;
  height: 298px;
  display: flex;
  align-items: center;
  .box {
    width: 300px;
    height: 231px;
    background: url("../assets/images/卡片bg@2x.png") no-repeat;
    background-size: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin: 0 10px;
    .title {
      font-size: 18px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #ffffff;
      line-height: 21px;
      letter-spacing: 1px;
    }
    .heng {
      width: 16px;
      height: 4px;
      background: #00aaff;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      margin: 8px 0 16px;
    }
    .lists {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      div {
        display: flex;
        justify-content: space-between;
      }
      .text {
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        letter-spacing: 1px;
      }
      .money {
        font-size: 16px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #ffffff;
        letter-spacing: 1px;
      }
    }
  }
}
.main {
  .content {
    display: flex;
    padding: 24px 46px;
    .left {
      position: relative;
      flex: 1;
      width: 1338px;
      // margin-right: 35px;
      // padding-right: 35px;
      .top-total {
        display: flex;
        .item {
          display: flex;
          align-items: center;
          width: 441px;
          height: 115px;
          background: url("../assets/images/card@2x.png");
          background-size: 100% 100%;
          font-size: 0;
          &.addbody {
            width: 441px;
          }
          & + .item {
            margin-left: 25px;
          }
          &:nth-child(2) {
            background-image: url("../assets/images/card@2x (1).png");
            .num,
            .unit {
              color: #00d5ee;
            }
          }
          &:nth-child(3) {
            background-image: url("../assets/images/card@2x (2).png");
            .num,
            .unit {
              color: #f2b800;
            }
          }
          .icon {
            width: 60px;
            margin: 0 0 0 30px;
            img {
              width: 100%;
            }
          }
          .body {
            padding: 0 20px;
            transform: translateX(170px);
            & + .body {
              border-left: 1px solid #eef8ff;
            }
          }
          .num {
            font-size: 32px;
            font-weight: 600;
            font-style: italic;
            color: #00d387;
          }
          .unit {
            font-size: 16px;
            margin-left: 3px;
            color: #00d387;
          }
          .name {
            font-size: 16px;
            line-height: 30px;
          }
        }
      }
      .year-total {
        margin: 30px 0;
        width: 239px;
        height: 427px;
        background: url(https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata1/year.png);
        background-size: 100% 100%;
        .header {
          height: 70px;
          line-height: 70px;
          text-align: center;
          font-size: 24px;
        }
        .list {
          .round-rotate {
            margin: 10px 0;
          }
        }
      }
      .county-total {
        font-size: 18px;
        margin-top: 30px;
        border-top: 1px solid rgba(0, 149, 255, 0.5);
        border-left: 1px solid rgba(0, 149, 255, 0.5);
        table {
          width: 100%;
          tr {
            &.area {
              td {
                background: rgba(0, 63, 152, 1);
                text-shadow: 0 0 2px #007ffc;
              }
            }
            &.num {
              td {
                &:nth-child(odd) {
                  .line {
                    background-image: linear-gradient(
                      0deg,
                      rgba(247, 181, 0, 0) 0%,
                      rgba(247, 181, 0, 0.75) 100%
                    );
                    border-color: rgba(#f7b500, 0.7);
                  }
                }
              }
            }
            td {
              text-align: center;
              vertical-align: bottom;
              padding: 5px 0;
              line-height: 42px;
              border-right: 1px solid rgba(0, 149, 255, 0.5);
              border-bottom: 1px solid rgba(0, 149, 255, 0.5);
              background: rgba(0, 63, 152, 0.4);
              &.header {
                color: #00aaff;
              }
            }
          }
        }
        .bar {
          position: relative;
          height: 115px;
          width: 70%;
          margin: 0 auto;
          .text {
            position: absolute;
            width: 100%;
            bottom: 0;
          }
          .line {
            position: absolute;
            width: 100%;
            bottom: 0;
            height: 50%;
            background-image: linear-gradient(
              0deg,
              rgba(0, 199, 255, 0) 0%,
              rgba(0, 149, 255, 0.75) 100%
            );
            border: 2px solid rgba(#00afff, 0.7);
            border-bottom: none;
            transition: height 0.3s;
          }
        }
      }
      .map {
        position: absolute;
        top: 80px;
        left: 250px;
        right: 0;
        bottom: 250px;
      }
      .light-level {
        position: absolute;
        left: 50%;
        transform: translateX(-25%);
        top: 540px;
        font-size: 0;
        text-align: center;
        li {
          margin: 0 8px;
          display: inline-block;
          cursor: pointer;
          width: 160px;
          height: 56px;
          line-height: 56px;
          background-size: 100% 100%;
          &:nth-child(1) {
            background-image: url(../assets/images/green_bg.png);
          }
          &:nth-child(2) {
            background-image: url(../assets/images/yellow_bg.png);
          }
          &:nth-child(3) {
            background-image: url(../assets/images/red_bg.png);
          }
          img {
            display: inline-block;
            width: 40px;
            vertical-align: top;
            margin-top: 7px;
          }
          span {
            display: inline-block;
            vertical-align: top;
            &.num {
              font-size: 18px;
              font-style: italic;
              margin: 0 2px 0 6px;
            }
            &.unit {
              font-size: 14px;
            }
          }
        }
      }
    }
    .right {
      width: 440px;
      margin-left: 35px;
      ::v-deep .el-carousel__container {
        height: 234px;
        margin: 21px 0;
      }
      .plant-list {
        overflow: hidden;
        li {
          display: flex;
          padding: 0 16px;
          height: 54px;
          line-height: 54px;
          font-size: 18px;
          background: url(https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata1/plant.png);
          background-size: 100% 100%;
          & + li {
            margin-top: 6px;
          }
          .name {
            width: 130px;
            text-shadow: 0 0 2px #007ffc;
          }
          .num {
            width: 110px;
            color: #00aaff;
          }
          .bar {
            flex: 1;
            .line {
              margin-top: 13px;
              height: 30px;
              width: 50%;
              background-color: #007ffc;
            }
          }
        }
      }
      .garden-list {
        margin: 10px 0 0;
        &.garden-list-body {
          margin: 0;
          height: 400px;
          overflow: hidden;
          li {
            cursor: pointer;
          }
        }
        &.income {
          margin: 0 0 22px 0;
        }
        li {
          font-size: 0;
          &:nth-child(even) {
            background: rgba(0, 63, 152, 0.3);
          }
          span {
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            letter-spacing: 1px;
            display: inline-block;
            width: 30%;
            font-size: 16px;
            text-align: center;
            line-height: 46px;
            &:nth-child(2) {
              color: #fec700;
              width: 35%;
            }
            &:nth-child(3) {
              color: #65d05f;
              width: 35%;
            }
            &:nth-child(4) {
              color: #0095ff;
            }
          }
          border-bottom: 1px solid rgba(0, 149, 255, 0.5);
        }
      }
    }
  }
}
</style>
